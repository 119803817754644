<template>
  <a-card :bordered="true" class="ant-card-no-padding">
    <div class="household-analysis-container">
      <div class="txt-20 txt-black txt-bold txt-font-din-medium pl-20 flex align-center">
        {{ $t('components.titles.allTeams') }}
      </div>
      <tr class="household-analysis-header txt-bold txt-font-din-medium txt-uppercase">
        <th>{{ $tc('components.labels.team', 1) }}</th>
        <th>{{ $t('components.labels.clusterHash') }}</th>
        <th>{{ $t('values.status') }}</th>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <th v-html="$t('components.labels.hhConsentedOverHHAssigned')"></th>
        <th>{{ $t('components.labels.hhRefused') }}</th>
        <th>{{ $t('components.labels.hhAbsent') }}</th>
         <!-- eslint-disable-next-line vue/no-v-html -->
        <th v-html="$t('components.labels.childrenMeasuredOverChildrenEligible')"></th>
        <th>{{ $t('components.labels.childrenMalnourished') }}</th>
        <th>{{ $t('components.labels.childrenReferred') }}</th>
      </tr>
      <tbody v-if="isLoading" class="household-analysis-rows">
        <div v-if="isLoading" class="loading-container">
          <a-spin/>
        </div>
      </tbody>
      <tbody v-else class="household-analysis-rows">
        <tr
          v-for="household in householdEntries"
          :key="`${household.teams.join(', ')}-${household.cluster}`"
          :style="{ background: getColor(household.teams).opacity }"
          class="household-analysis-row">
          <td class="household-analysis-row-team-column"
            :style="{ '--teamColor': getColor(household.teams).full }">
            {{ household.teams.join(', ') }}
          </td>
          <td>{{ household.cluster }}</td>
          <td>
            <span v-if="household.status === 'Not Accessible' || household.status === 'Unassigned Cluster'" class="not-accessible">
              {{ translatedStatus[household.status] }}
            </span>
            <span v-else>
              {{ translatedStatus[household.status] }}
            </span>
          </td>
          <td>{{ household.consented }} / {{ numberOfHouseholdsPerCluster }}</td>
          <td>{{ household.refused }}</td>
          <td>{{ household.absent }}</td>
          <td>{{ household.childrenMeasured }} / {{ household.childrenEligible }}</td>
          <td>{{ household.childrenMalnourished }}</td>
          <td>{{ household.childrenReferred }}</td>
        </tr>
        <tr class="household-analysis-footer txt-bold txt-font-din-medium txt-uppercase">
          <td>{{ $t('components.labels.total') }}</td>
          <td>{{ householdEntries.length }}</td>
          <td>-</td>
          <td>
            {{ summedClusters.consented }} /
            {{ (summedClusters.length * numberOfHouseholdsPerCluster) }}
          </td>
          <td>{{ summedClusters.refused }}</td>
          <td>{{ summedClusters.absent }}</td>
          <td>{{ summedClusters.measuredChildren }} / {{ summedClusters.eligibleChildren }}</td>
          <td>{{ summedClusters.childrenMalnourished }}</td>
          <td>{{ summedClusters.childrenReferred }}</td>
        </tr>
      </tbody>
    </div>
  </a-card>
</template>

<script>

import { mapState } from 'vuex';
import sumBy from 'lodash/sumBy';
import toNumber from 'lodash/toNumber';
import { getRandomColor } from '../../../util/util';

export default {
  name: 'ProjectDashboardHouseholdAnalysis',
  props: {
    submissions: {
      type: Array,
      default: () => []
    },
    householdEntries: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      numberOfHouseholdsPerCluster: state => state.survey.metadata.householdDayTeam || 0,
      numberOfHouseholdsPerDay: state => state.survey.metadata.householdPerDayTeam || 0
    }),
    translatedStatus() {
      return {
        'Not Accessible': this.$t('components.labels.notAccessible'),
        Complete: this.$t('components.labels.complete'),
        'Partially Complete': this.$t('components.labels.partiallyComplete'),
        'Cluster Conflict': this.$t('components.labels.clusterConflict'),
        'Unassigned Cluster': this.$t('components.labels.unassignedCluster')
      };
    },
    summedClusters() {
      const summable = (this.householdEntries || []).map((item) => ({
        consented: toNumber(item.consented),
        refused: toNumber(item.refused),
        absent: toNumber(item.absent),
        childrenMeasured: toNumber(item.childrenMeasured),
        childrenEligible: toNumber(item.childrenEligible),
        childrenMalnourished: toNumber(item.childrenMalnourished),
        childrenReferred: toNumber(item.childrenReferred)
      }));
      return {
        consented: sumBy(summable, 'consented'),
        refused: sumBy(summable, 'refused'),
        absent: sumBy(summable, 'absent'),
        measuredChildren: sumBy(summable, 'childrenMeasured'),
        eligibleChildren: sumBy(summable, 'childrenEligible'),
        length: summable.length,
        childrenMalnourished: sumBy(summable, 'childrenMalnourished'),
        childrenReferred: sumBy(summable, 'childrenReferred')
      };
    }
  },
  methods: {
    getColor(teamIds) {
      if (teamIds.length >= 1) {
        return {
          opacity: getRandomColor(teamIds[0], 0.1),
          full: getRandomColor(teamIds[0], 1)
        };
      }

      return {
        opacity: '#fff',
        full: '#fff'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.loading-container {
  text-align: center;
  padding-top: 30px;
  min-height: 200px;
}
.household-analysis-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 70px 1fr 0px;
  gap: 0px 0px;
  grid-template-areas:
    "."
    "."
    "."
    ".";
  min-width: 100%;
  overflow-x: scroll;
}

.household-analysis-header,
.household-analysis-row,
.household-analysis-footer {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: auto;
  align-items: center;
  text-align: center;
  justify-items: center;
  min-height: 50px;
}

.household-analysis-rows {
  max-height: 275px;
  overflow-x: scroll
}

.household-analysis-row {
  min-height: 30px;
  border-bottom: solid 1px #97979757;
  &:last-child {
    border-bottom: none;
  }
}

.household-analysis-header {
  background: #f6f6f6;
  border-top: solid 1px #97979757;
  border-bottom: solid 1px #97979757;

  @media (max-width: 1500px) {
    font-size: 12px;
  }

  @media (max-width: 1200px) {
    font-size: 10px;
  }

  @media (max-width: 100px) {
    font-size: 9px;
  }

  @media (max-width: 800px) {
    font-size: 8px;
  }
}

.household-analysis-footer {
  border-top: solid 1px #97979757;
}

.household-analysis-row-team-column {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 3px;
    background-color: var(--teamColor);
  }
}

.not-accessible {
  background: #ffe4e4;
  color: #be0600;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
